import Entity from "app/core/entities/Entity";

class Street extends Entity {
    /**
     * @method getName
     * @returns {string}
     */
    getName() {
        return this.entity.name || "";
    }
}

export default Street;
