import Env from "app/core/environment";
import FaqEntity from "app/core/entities/faq/FAQ";
import HttpClient from "app/core/utilites/httpClient/HttpClient";

import Repository from "./repository/Repository";
import Faq from "./Faq";

let faq = new Faq({
    dependencies: {
        Repository: new Repository({
            urls: {
                getAllFaq: {
                    domain: Env.getInstance().getInfoBlockServiceHost(),
                    path: "/api/faq",
                    query: {
                        page: 1,
                        itemsPerPage: 30
                    }
                },
                getAnswer: {
                    domain: Env.getInstance().getInfoBlockServiceHost(),
                    path: "/api/faq/:question",
                    params: {
                        question: ""
                    }
                },
                getFaqByCategory: {
                    domain: Env.getInstance().getInfoBlockServiceHost(),
                    path: "/api/faq",
                    query: {
                        "category.alias": ""
                    }
                },
                getFaqByActiveIngredient: {
                    domain: Env.getInstance().getInfoBlockServiceHost(),
                    path: "/api/faq",
                    query: {
                        "mnn.alias": ""
                    }
                }
            },
            dependencies: {
                HttpClient
            }
        }),
        FaqEntity
    }
});

export default {
    getInstance() {
        return faq;
    }
};
