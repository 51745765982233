class Transformer {
    constructor(props) {
        this._Env = props.dependencies.Env;
    }

    /**
     * @public
     * @method transform
     * @param data {Object}
     * @return {Object}
     */
    transform(data) {
        return {
            id: data.delivery_id,
            type: data.delivery_type_id,
            serviceType: data.delivery_service_type_id,
            title: data.delivery_name,
            description: data.delivery_description,
            warning: data.delivery_warning,
            logo: {
                src: {
                    original: `${data.delivery_logo}`
                }
            },
            price: data.delivery_price
        };
    }
}

export default Transformer;
