import Enum from "app/core/utilites/enum/Enum";

let deliveryServiceType = new Enum({
    fields: [
        {key: "a24", value: -1},
        {key: "ukrPost", value: 1},
        {key: "newPost", value: 2},
        {key: "justin", value: 3},
        {key: "meestExpress", value: 4},
        {key: "iPost", value: 5},
        {key: "uklon", value: 6}
    ]
});

export default {
    getInstance() {
        return deliveryServiceType;
    }
};
