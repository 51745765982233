import Entity from "app/core/entities/Entity";
import Image from "app/core/entities/image/Image";

class Delivery extends Entity {
    /**
     * @method getLogo
     * @returns {Image}
     */
    getLogo() {
        return new Image(this.entity.logo);
    }

    /**
     * @method getTitle
     * @returns {string}
     */
    getTitle() {
        return this.entity.title || "";
    }

    /**
     * @method getType
     * @return {number|string}
     */
    getType() {
        return this.entity.type || 0;
    }

    /**
     * @method getServiceType
     * @return {number|string}
     */
    getServiceType() {
        return this.entity.serviceType || 0;
    }

    /**
     * @method getDescription
     * @returns {string}
     */
    getDescription() {
        return this.entity.description || "";
    }

    /**
     * @public
     * @method getWarning
     * @return {string}
     */
    getWarning() {
        return this.entity.warning || "";
    }

    /**
     * @public
     * @method getAddressAsText
     * @return {string}
     */
    getAddressAsText() {
        return this.entity.address || "";
    }

    /**
     * @method getPrice
     * @returns {number}
     */
    getPrice() {
        return Number(this.entity.price) || 0;
    }
}

export default Delivery;
