/* eslint-disable max-len */
import _ from "lodash";

class Delivery {
    constructor(props) {
        /**
         * @private
         * @property _Repository
         * @type {Repository}
         */
        this._Repository = props.dependencies.Repository;

        /**
         * @private
         * @property Resource
         * @type {Object}
         */
        this._Resource = props.dependencies.Resource;

        /**
         * @private
         * @property _DrugstoreDetector
         * @type {Object}
         */
        this._DrugstoreDetector = props.dependencies.DrugstoreDetector;

        /**
         * @private
         * @property _CityEntity
         * @type {City}
         */
        this._CityEntity = props.dependencies.CityEntity;

        /**
         * @private
         * @property _StreetEntity
         * @type {Street}
         */
        this._StreetEntity = props.dependencies.StreetEntity;

        /**
         * @private
         * @property _DeliveryEntity
         * @type {Delivery}
         */
        this._DeliveryEntity = props.dependencies.DeliveryEntity;

        /**
         * @property _DepartmentEntity
         * @type {Department}
         * @private
         */
        this._DepartmentEntity = props.dependencies.DepartmentEntity;

        /**
         * @property _DeliveryPointMarkerEntity
         * @type {DeliveryPointMarker}
         * @private
         */
        this._DeliveryPointMarkerEntity = props.dependencies.DeliveryPointMarkerEntity;

        /**
         * @property _DeliveryServiceTypeEnum
         * @type {Enum}
         * @private
         */
        this._DeliveryServiceTypeEnum = props.dependencies.DeliveryServiceTypeEnum;

        /**
         * @property _DeliveryTransformerFromBitrix
         * @type {Transformer}
         * @private
         */
        this._DeliveryTransformerFromBitrix = props.dependencies.DeliveryTransformerFromBitrix;
    }

    /**
     * @method _buildDeliveryPointMarkers
     * @param items {Department[]}
     * @param serviceType {string|number}
     * @returns {DeliveryPointMarker[]}
     * @private
     */
    _buildDeliveryPointMarkers(items, serviceType) {
        return items.map((item) => {
            let entity = new this._DeliveryPointMarkerEntity({});

            return entity
                .setId(item.getId())
                .setIconUrl(this._getUrlIconByDeliveryServiceType(item, serviceType))
                .setLatitude(item.getLatitude())
                .setLongitude(item.getLongitude())
                .setName(item.getName())
                .setAddressAsText(item.getStreet())
                .setPhone(item.getPhone())
                .setWorkTime(item.getWorkTime())
                .setSelfDelivery(item.hasSelfDelivery());
        });
    }

    /**
     * @method _getUrlIconByDeliveryServiceType
     * @param item {Department}
     * @param serviceType {string|number}
     * @private
     */
    _getUrlIconByDeliveryServiceType(item, serviceType) {
        let result = "";

        if (this._DeliveryServiceTypeEnum.isNewPost(serviceType)) {
            result = this._Resource.links.icons.pin.newPost;
        }

        if (this._DeliveryServiceTypeEnum.isA24(serviceType)) {
            result = this._getUrlIconByDrugstoreName(item.getName());
        }

        return result;
    }

    /**
     * @private
     * @method _getIconMarkerByDrugstoreName
     * @params name {string}
     * @returns {string}
     */
    _getUrlIconByDrugstoreName(name = "") {
        let result = this._Resource.links.icons.pin.partner;

        if (this._DrugstoreDetector.isA24(name)) {
            result = this._Resource.links.icons.pin.apteka24;
        }

        return result;
    }

    /**
     * @method getPopularCities
     * @param success {Function}
     * @param error {Function}
     * @returns {Repository}
     */
    getPopularCities(success, error) {
        if (_.isFunction(success) && _.isFunction(error)) {
            this._Repository.getPopularCities((items) => {
                success(items.map((item) => new this._CityEntity(item)));
            }, error);
        }

        return this;
    }

    /**
     * @method getCitiesByName
     * @param name {string}
     * @param success {Function}
     * @param error {Function}
     * @returns {Delivery}
     */
    getCitiesByName(name, success, error) {
        if (_.isString(name) && _.isFunction(success) && _.isFunction(error)) {
            this._Repository.getCitiesByName(name, (items) => {
                success(
                    items
                        .filter((item) => (item.name.toLowerCase().includes(name.toLowerCase())))
                        .map((item) => new this._CityEntity(item))
                );
            }, error);
        }

        return this;
    }

    /**
     * @example
     *
     * params = {
     *     cityId: (string|number),
     *     streetName: string,
     *     deliveryServiceType: (string|number|undefined)
     * }
     *
     * @method getStreetsByName
     * @param params {Object}
     * @param success {Function}
     * @param error {Function}
     * @returns {Delivery}
     */
    getStreetsByName(params, success, error) {
        if (_.isPlainObject(params) && _.isString(params.cityId) && _.isString(params.streetName) && _.isFunction(success) && _.isFunction(error)) {
            this._Repository.getStreetsByName(params, (items) => {
                success(items.map((item) => new this._StreetEntity(item)));
            }, error);
        }

        return this;
    }

    /**
     * @method getDepartments
     * @param cityId {string|number}
     * @param serviceType {string|number}
     * @param basketId {string|number}
     * @param success {Function}
     * @param error {Function}
     * @return {Delivery}
     */
    getDepartments(cityId, serviceType, basketId, success, error) {
        if (cityId && serviceType && _.isFunction(success) && _.isFunction(error)) {
            this._Repository.getDepartments(cityId, serviceType, basketId, (items) => {
                let itemsEntity = items.map((item) => new this._DepartmentEntity(item));

                success(
                    itemsEntity,
                    this._buildDeliveryPointMarkers(itemsEntity, serviceType)
                );
            }, error);
        }

        return this;
    }

    /**
     * @method getDeliveriesByProduct
     * @param cityId {string|number}
     * @param productAlias {string}
     * @param success {Function}
     * @param error {Function}
     * @return {Delivery}
     */
    getDeliveriesByProduct(cityId, productAlias, success, error) {
        if (cityId && productAlias && _.isFunction(success) && _.isFunction(error)) {
            this._Repository.getDeliveriesByProduct(cityId, productAlias, (items) => {
                success(
                    items.map((item) => new this._DeliveryEntity(this._DeliveryTransformerFromBitrix.transform(item)))
                );
            }, error);
        }

        return this;
    }

    /**
     * @public
     * @method convertCityToEntity
     * @param city {Object}
     * @returns {City}
     */
    convertCityToEntity(city) {
        return new this._CityEntity(city);
    }

    /**
     * @public
     * @method convertDepartmentToEntity
     * @param department {Object}
     * @returns {City}
     */
    convertDepartmentToEntity(department) {
        return new this._DepartmentEntity(department);
    }
}

export default Delivery;
