import _ from "lodash";

class Repository {
    constructor(props) {
        /**
         * @example
         *
         * urls = {
         *     getReview: {
         *         domain: string,
         *         path: string
         *     }
         * }
         *
         * @property urls
         * @type {Object}
         */
        this.urls = props.urls;

        /**
         * @property HttpClient
         * @type {HttpClient}
         */
        this.HttpClient = props.dependencies.HttpClient;

        /**
         * @property httpClient
         * @type {HttpClient}
         */
        this.httpClient = new props.dependencies.HttpClient();
    }

    /**
     * @public
     * @method getAllFaq
     * @param params {{
     *     page: number,
     *     itemsPerPage: number
     * }}
     * @param success {Function}
     * @param error {Function}
     * @returns {Repository}
     */
    getAllFaq(params, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.getAllFaq.domain)
            .request({
                path: this.urls.getAllFaq.path,
                method: this.HttpClient.methods.GET,
                query: _.merge({}, this.urls.getAllFaq.query, {
                    page: params.page,
                    itemsPerPage: params.itemsPerPage
                })
            })
            .then((response) => {
                success(response.data);
            }, error);
    }

    /**
     * @public
     * @method getFaq
     * @param alias {string}
     * @param success {Function}
     * @param error {Function}
     * @returns {Repository}
     */
    getAnswer(alias, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.getAnswer.domain)
            .request({
                path: this.urls.getAnswer.path,
                method: this.HttpClient.methods.GET,
                params: _.merge({}, this.urls.getAnswer.params, {
                    question: alias
                })
            })
            .then((response) => {
                success(response.data);
            }, error);
    }

    /**
     * @public
     * @method getFaqByCategory
     * @param category {String}
     * @param success {Function}
     * @param error {Function}
     * @returns {Promise}
     */
    getFaqByCategory(category, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.getFaqByCategory.domain)
            .request({
                path: this.urls.getFaqByCategory.path,
                method: this.HttpClient.methods.GET,
                query: _.merge({}, this.urls.getFaqByCategory.query, {
                    "category.alias": category
                })
            })
            .then((response) => {
                success(response.data);
            }, error);
    }

    /**
     * @public
     * @method getFaqByActiveIngredient
     * @param activeIngredient {String}
     * @param success {Function}
     * @param error {Function}
     * @returns {Promise}
     */
    getFaqByActiveIngredient(activeIngredient, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.getFaqByActiveIngredient.domain)
            .request({
                path: this.urls.getFaqByActiveIngredient.path,
                method: this.HttpClient.methods.GET,
                query: _.merge({}, this.urls.getFaqByActiveIngredient.query, {
                    "mnn.alias": activeIngredient
                })
            })
            .then((response) => {
                success(response.data);
            }, error);
    }
}

export default Repository;
