import _ from "lodash";

class Faq {
    constructor(props) {
        /**
         * @property Repository
         * @type {Repository}
         */
        this.Repository = props.dependencies.Repository;

        /**
         * @property FaqEntity
         * @type {Faq}
         */
        this.FaqEntity = props.dependencies.FaqEntity;
    }

    /**
     * @public
     * @method getAllFaq
     * @param params {{
     *     page: number,
     *     itemsPerPage: number
     * }}
     * @param success {Function}
     * @param error {Function}
     * @returns {Faq}
     */
    getAllFaq(params, success, error) {
        if (_.isFunction(success) && _.isFunction(error)) {
            this.Repository.getAllFaq(params, success, error);
        }

        return this;
    }

    /**
     * @public
     * @method getAnswer
     * @param alias {string}
     * @param success {Function}
     * @param error {Function}
     * @returns {Faq}
     */
    getAnswer(alias, success, error) {
        if (_.isString(alias) && _.isFunction(success) && _.isFunction(error)) {
            this.Repository.getAnswer(alias, success, error);
        }

        return this;
    }

    /**
     * @public
     * @method getFaqByActiveIngredient
     * @param activeIngredient {String}
     * @param success {Function}
     * @param error {Function}
     * @returns {Faq}
     */
    getFaqByActiveIngredient(activeIngredient, success, error) {
        if (_.isString(activeIngredient) && _.isFunction(success) && _.isFunction(error)) {
            this.Repository.getFaqByActiveIngredient(activeIngredient, success, error);
        }

        return this;
    }

    /**
     * @public
     * @method getFaqByCategory
     * @param category {String}
     * @param success {Function}
     * @param error {Function}
     * @returns {Faq}
     */
    getFaqByCategory(category, success, error) {
        if (_.isString(category) && _.isFunction(success) && _.isFunction(error)) {
            this.Repository.getFaqByCategory(category, success, error);
        }

        return this;
    }

    /**
     * @public
     * @method convertFaqToEntity
     * @param item {Object}
     * @returns {Array}
     */
    convertFaqToEntity(item) {
        return new this.FaqEntity(item);
    }
}

export default Faq;
