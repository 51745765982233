import Env from "app/core/environment";
import Resource from "app/core/resource";

import CityEntity from "app/core/entities/delivery/City";
import StreetEntity from "app/core/entities/delivery/Street";
import DeliveryEntity from "app/core/entities/delivery/Delivery";
import DepartmentEntity from "app/core/entities/delivery/Department";
import DeliveryPointMarkerEntity from "app/core/entities/map/marker/deliveryPointMarker/DeliveryPointMarker";

import DeliveryServiceTypeEnum from "app/core/utilites/enum/delivery/serviceType";

import HttpClient from "app/core/utilites/httpClient/HttpClient";
import DrugstoreDetector from "app/core/utilites/drugstoreDetector";

import DeliveryTransformerFromBitrix from "app/core/utilites/entityTransformer/delivery/fromBitrix";

import Repository from "./repository/Repository";
import Delivery from "./Delivery";

let delivery = new Delivery({
    dependencies: {
        Repository: new Repository({
            dependencies: {
                HttpClient
            },
            urls: {
                getPopularCities: {
                    domain: Env.getInstance().getCheckoutServiceHost(),
                    path: "/pub/v4/deliveries/cities/popular"
                },
                getCitiesByName: {
                    domain: Env.getInstance().getCheckoutServiceHost(),
                    path: "/pub/v4/deliveries/cities",
                    query: {
                        cityName: ""
                    }
                },
                getStreetsByName: {
                    domain: Env.getInstance().getCheckoutServiceHost(),
                    path: "/pub/v4/deliveries/cities/:cityId/streets",
                    query: {
                        deliveryServiceType: "",
                        streetName: ""
                    }
                },
                getDepartments: {
                    domain: Env.getInstance().getCheckoutServiceHost(),
                    path: "/pub/v4/deliveries/cities/:cityId/warehouses",
                    query: {
                        deliveryServiceType: "",
                        basketId: ""
                    }
                },
                getDeliveriesByProduct: {
                    domain: Env.getInstance().getCheckoutServiceHost(),
                    path: "/pub/v4/delivery-data/products/:alias",
                    query: {
                        cityId: ""
                    }
                }
            }
        }),
        DeliveryTransformerFromBitrix: DeliveryTransformerFromBitrix.getInstance(),
        DeliveryServiceTypeEnum: DeliveryServiceTypeEnum.getInstance(),
        DrugstoreDetector: DrugstoreDetector.getInstance(),
        Resource,
        CityEntity,
        StreetEntity,
        DeliveryEntity,
        DepartmentEntity,
        DeliveryPointMarkerEntity
    }
});

export default {
    getInstance() {
        return delivery;
    }
};
