import _ from "lodash";

import Entity from "app/core/entities/Entity";

class Department extends Entity {
    /**
     * @public
     * @method isAvailableDiscountProgram
     * @returns {boolean}
     */
    isAvailableDiscountProgram() {
        return Boolean(this.entity.loyalty);
    }

    /**
     * @public
     * @method hasSelfDelivery
     * @returns {boolean}
     */
    hasSelfDelivery() {
        return Boolean(this.entity.selfService);
    }

    /**
     * @public
     * @method getLatitude
     * @returns {number}
     */
    getLatitude() {
        return this.entity.latitude || 0;
    }

    /**
     * @public
     * @method getLongitude
     * @returns {number}
     */
    getLongitude() {
        return this.entity.longitude || 0;
    }

    /**
     * @public
     * @method getName
     * @returns {string}
     */
    getName() {
        return this.entity.name || "";
    }

    /**
     * @public
     * @method setName
     * @param value {string}
     * @returns {Department}
     */
    setName(value) {
        if (_.isString(value)) {
            this.entity.name = value;
        }

        return this;
    }

    /**
     * @public
     * @method getPhone
     * @returns {string}
     */
    getPhone() {
        return this.entity.phone || "";
    }

    /**
     * @public
     * @method getStreet
     * @returns {string}
     */
    getStreet() {
        return this.entity.street || "";
    }

    /**
     * @public
     * @method getWorkTime
     * @returns {string}
     */
    getWorkTime() {
        return this.entity.workTime || "";
    }
}

export default Department;
